<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;

const challenges = [
    {
        link: '/kh/mangel-an-bewerbungen',
        title: 'Mangel an Bewerbungen',
    },
    {
        link: '/kh/lange-besetzungsdauer',
        title: 'Lange Besetzungsdauer',
    },
    {
        link: '/kh/starker-mitbewerb',
        title: 'Starker Mitbewerb',
    },
    {
        link: '/kh/hoher-personalbedarf',
        title: 'Hoher Personalbedarf',
    },
];
</script>

<template>
    <div
        class="grid max-w-content grid-cols-1 gap-5 pb-4 lg:mx-auto lg:grid-cols-[5fr_7fr] lg:p-7"
    >
        <ul class="flex flex-col justify-start gap-5 lg:items-start">
            <li>
                <NavigationPanelFlyoutNavItem
                    to="/produkte/stelleninserate"
                    title="Stelleninserate"
                    description="Jobsuchende effektiv erreichen"
                />
            </li>
            <li class="flex flex-col gap-2">
                <NavigationPanelFlyoutNavItem
                    to="/produkte/talentsuche"
                    title="Aktive Talentsuche"
                    description="Interessante Talente direkt kontaktieren"
                />
            </li>
            <li class="flex flex-col gap-2">
                <NavigationPanelFlyoutNavItem
                    to="/produkte/arbeitgeberprofil"
                    title="Arbeitgeberprofil"
                    description="Talente für Ihr Unternehmen begeistern"
                />
            </li>
        </ul>
        <div class="flex flex-col justify-start gap-8 lg:items-start">
            <div class="flex flex-col gap-5">
                <NavigationPanelFlyoutNavItem
                    :to="`${content_Url}/hr/stelleninserate/produktberater`"
                    title="Welches Stelleninserat ist das richtige für Sie?"
                    description="Unser Produktberater hilft Ihnen bei der Entscheidungsfindung.*"
                    external
                />
                <CommExcentosLegalText class="text-xs lg:hidden" />
            </div>
            <div class="flex w-full flex-col gap-3 lg:p-4">
                <h3 class="text-lg font-semibold lg:text-xl">
                    Ihre Herausforderungen, unsere Lösungen
                </h3>
                <ul
                    class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:flex lg:flex-wrap"
                >
                    <li
                        v-for="(challenge, idx) in challenges"
                        :key="`${challenge.title}-${idx}`"
                    >
                        <HeadlessPopoverButton
                            class="flex h-full w-full items-center justify-center rounded bg-blue-50 p-5 text-center transition-all duration-300 ease-out lg:min-h-[80px] lg:w-[176px] lg:hover:bg-gray-700 lg:hover:text-white"
                            data-gtm-element="HEAD: Main Navigation"
                            :data-gtm-element-detail="challenge.title"
                            @click="navigateTo(challenge.link)"
                        >
                            {{ challenge.title }}
                        </HeadlessPopoverButton>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <NavigationPanelFooter class="hidden lg:block">
        <template #trailingContent>
            <div class="shrink">
                <CommExcentosLegalText class="text-right text-xs" />
            </div>
        </template>
    </NavigationPanelFooter>
</template>

<script setup lang="ts">
import { useContactRequestForm } from '@b2b-platform/commerce/composables/useContactRequestForm';

const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;

const { open: openContactRequestForm } = useContactRequestForm();
</script>

<template>
    <div class="bg-gray-50">
        <div
            class="mx-auto flex w-full max-w-content grid-cols-[5fr_7fr] flex-col items-start gap-7 px-5 py-6 lg:grid lg:px-7 lg:py-6"
        >
            <div class="flex gap-4">
                <p class="text-lg font-semibold">Persönliche Beratung?</p>
                <HeadlessPopoverButton
                    data-gtm-element="HEAD: Main Navigation"
                    data-gtm-element-detail="Jetzt kontaktieren"
                    @click="
                        openContactRequestForm();
                        navigateTo('#contact-request');
                    "
                >
                    <JamBaseLink as="a" variant="dark-gray">
                        Jetzt kontaktieren
                    </JamBaseLink>
                </HeadlessPopoverButton>
            </div>

            <div class="flex items-center justify-between gap-4 lg:pl-4">
                <div class="flex shrink-0 gap-4">
                    <p class="text-lg font-semibold">Noch Fragen?</p>
                    <JamBaseLink
                        variant="dark-gray"
                        as="a"
                        :href="`${content_Url}/hr/faqs`"
                        target="_blank"
                    >
                        FAQ
                    </JamBaseLink>
                </div>

                <slot name="trailingContent" />
            </div>
        </div>
    </div>
</template>

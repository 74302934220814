<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const k4_Url = runtimeConfig.public.karriere.url;
const content_Url = runtimeConfig.public.karriere.content.url;

const paymentMethods = [
    {
        logo: '/payment-logos/visa.svg',
        name: 'VISA',
    },
    {
        logo: '/payment-logos/mastercard.svg',
        name: 'Mastercard',
    },
    {
        logo: '/payment-logos/paypal.svg',
        name: 'PayPal',
    },
    {
        logo: '/payment-logos/klarna.svg',
        name: 'Klarna',
    },
    {
        logo: '/payment-logos/invoice.svg',
        name: 'Rechnung',
    },
];

const socialMediaLinks = [
    {
        logo: '/social-media-logos/instagram.svg',
        name: 'Instagram',
        url: 'https://www.instagram.com/karriere.at',
    },
    {
        logo: '/social-media-logos/linkedin-in.svg',
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/karriere-at/mycompany/?viewAsMember=true',
    },
    {
        logo: '/social-media-logos/facebook-f.svg',
        name: 'Facebook',
        url: 'https://www.facebook.com/karriere.at',
    },
    {
        logo: '/social-media-logos/tiktok.svg',
        name: 'TikTok',
        url: 'https://www.tiktok.com/@karriere.at',
    },
    {
        logo: '/social-media-logos/youtube.svg',
        name: 'YouTube',
        url: 'https://www.youtube.com/user/karriereatchannel',
    },
    {
        logo: '/social-media-logos/Pinterest.svg',
        name: 'Pinterest',
        url: 'https://www.pinterest.at/karriereat',
    },
];

const productsLinks = [
    {
        label: 'Inserate schalten',
        url: '/produkte/stelleninserate',
    },
    {
        label: 'Talente suchen',
        url: '/produkte/talentsuche',
    },
    {
        label: 'Talente überzeugen',
        url: '/produkte/arbeitgeberprofil',
    },
];

const hrKnowHowLinks = [
    {
        isExternal: true,
        label: 'Downloads',
        url: `${content_Url}/hr/tipps`,
    },
    {
        isExternal: true,
        label: 'Events',
        url: `${content_Url}/hr/events`,
    },
    {
        isExternal: true,
        label: 'Podcast',
        url: `${content_Url}/lp/zeitausgleich`,
    },
];

const karriereAtLinks = [
    {
        isExternal: true,
        label: 'Für Jobsuchende',
        url: `${k4_Url}`,
    },
    {
        isExternal: true,
        label: 'Häufige Fragen',
        url: `${content_Url}/hr/faqs`,
    },
    {
        isExternal: true,
        label: 'Presse',
        url: `${k4_Url}/presse`,
    },
];

const legalLinks = [
    {
        isExternal: true,
        label: 'Impressum',
        url: `${k4_Url}/impressum`,
    },
    {
        isExternal: true,
        label: 'AGB',
        url: `${k4_Url}/agb`,
    },
    {
        isExternal: true,
        label: 'Datenschutz',
        url: `${k4_Url}/datenschutzerklaerung`,
    },
];
</script>

<template>
    <footer class="bg-gray-50">
        <div
            class="mx-auto flex max-w-content flex-col gap-5 p-5 lg:gap-7 lg:p-7 lg:pt-11"
        >
            <div class="flex flex-col gap-6 lg:grid lg:grid-cols-6">
                <div class="hidden flex-col gap-5 lg:flex">
                    <JamBaseText as="h3" variant="bold"> Produkte </JamBaseText>
                    <ul
                        class="flex flex-col gap-5 text-gray-600"
                        aria-label="Liste der verfügbaren Produkte"
                    >
                        <li
                            v-for="(productLink, index) in productsLinks"
                            :key="`${productLink.label}-${index}`"
                        >
                            <JamBaseLink
                                variant="gray"
                                :href="productLink.url"
                                as="nuxt-link"
                                >{{ productLink.label }}
                            </JamBaseLink>
                        </li>
                    </ul>
                </div>
                <div class="hidden flex-col gap-5 lg:flex">
                    <JamBaseText as="h3" variant="bold">
                        HR Know-how
                    </JamBaseText>
                    <ul
                        class="flex flex-col gap-5 text-gray-600"
                        aria-label="Ressourcen und Veranstaltungen für HR-Wissen"
                    >
                        <li
                            v-for="(hrKnowHowLink, index) in hrKnowHowLinks"
                            :key="`${hrKnowHowLink.label}-${index}`"
                        >
                            <JamBaseLink
                                variant="gray"
                                :href="hrKnowHowLink.url"
                                as="nuxt-link"
                                >{{ hrKnowHowLink.label }}
                            </JamBaseLink>
                        </li>
                    </ul>
                </div>
                <div class="hidden flex-col gap-5 lg:flex">
                    <JamBaseText as="h3" variant="bold">
                        karriere.at
                    </JamBaseText>
                    <ul
                        class="flex flex-col gap-5 text-gray-600"
                        aria-label="Informationen für Jobsuchende und Presse"
                    >
                        <li
                            v-for="(karriereAtLink, index) in karriereAtLinks"
                            :key="`${karriereAtLink.label}-${index}`"
                        >
                            <JamBaseLink
                                variant="gray"
                                :href="karriereAtLink.url"
                                as="nuxt-link"
                                :external="karriereAtLink.isExternal"
                                :target="
                                    karriereAtLink.isExternal ? 'blank_' : ''
                                "
                                >{{ karriereAtLink.label }}
                            </JamBaseLink>
                        </li>
                    </ul>
                </div>
                <div class="hidden flex-col items-start gap-5 lg:flex">
                    <JamBaseText as="h3" variant="bold">
                        Zahlungsarten
                    </JamBaseText>
                    <ul
                        class="grid grid-cols-2 gap-4"
                        aria-label="Liste der verfügbaren Zahlungsarten"
                    >
                        <li
                            v-for="(paymentMethod, index) in paymentMethods"
                            :key="`${paymentMethod.name}-${index}`"
                        >
                            <NuxtImg
                                width="64"
                                height="32"
                                decoding="auto"
                                :src="paymentMethod.logo"
                                :alt="`Zahlungsart ${paymentMethod.name}`"
                                loading="lazy"
                            />
                        </li>
                    </ul>
                </div>

                <!-- MOBILE -->
                <div class="lg:hidden">
                    <JamBaseCollapse
                        trigger-text="Produkte"
                        trigger-position="top"
                        trigger-variant="dark-gray"
                        trigger-classes="w-full"
                        class="border-b py-2"
                        trigger-content-classes="justify-between w-full py-3 font-semibold"
                        trigger-content-gap="gap-4"
                    >
                        <ul
                            class="flex flex-col gap-4 pb-6"
                            aria-label="Liste der verfügbaren Produkte"
                        >
                            <li
                                v-for="(productLink, index) in productsLinks"
                                :key="`${productLink.label}-${index}`"
                            >
                                <JamBaseLink
                                    variant="gray"
                                    :href="productLink.url"
                                    as="nuxt-link"
                                    >{{ productLink.label }}
                                </JamBaseLink>
                            </li>
                        </ul>
                    </JamBaseCollapse>
                    <JamBaseCollapse
                        trigger-text="HR Know-How"
                        trigger-position="top"
                        trigger-variant="dark-gray"
                        trigger-classes="w-full"
                        class="border-b py-2"
                        trigger-content-classes="justify-between w-full py-3 font-semibold"
                        trigger-content-gap="gap-4"
                    >
                        <ul
                            class="flex flex-col gap-4 pb-6"
                            aria-label="Ressourcen und Veranstaltungen für HR-Wissen"
                        >
                            <li
                                v-for="(hrKnowHowLink, index) in hrKnowHowLinks"
                                :key="`${hrKnowHowLink.label}-${index}`"
                            >
                                <JamBaseLink
                                    variant="gray"
                                    :href="hrKnowHowLink.url"
                                    as="nuxt-link"
                                    >{{ hrKnowHowLink.label }}
                                </JamBaseLink>
                            </li>
                        </ul>
                    </JamBaseCollapse>
                    <JamBaseCollapse
                        trigger-text="karriere.at"
                        trigger-position="top"
                        trigger-variant="dark-gray"
                        trigger-classes="w-full"
                        class="border-b py-2"
                        trigger-content-classes="justify-between w-full py-3 font-semibold"
                        trigger-content-gap="gap-4"
                    >
                        <ul
                            class="flex flex-col gap-4 pb-6"
                            aria-label="Informationen für Jobsuchende und Presse"
                        >
                            <li
                                v-for="(
                                    karriereAtLink, index
                                ) in karriereAtLinks"
                                :key="`${karriereAtLink.label}-${index}`"
                            >
                                <JamBaseLink
                                    variant="gray"
                                    :href="karriereAtLink.url"
                                    as="nuxt-link"
                                    :external="karriereAtLink.isExternal"
                                    :target="
                                        karriereAtLink.isExternal
                                            ? 'blank_'
                                            : ''
                                    "
                                    >{{ karriereAtLink.label }}
                                </JamBaseLink>
                            </li>
                        </ul>
                    </JamBaseCollapse>
                    <JamBaseCollapse
                        trigger-text="Zahlungsarten"
                        trigger-position="top"
                        trigger-variant="dark-gray"
                        trigger-classes="w-full"
                        trigger-content-classes="justify-between w-full pt-3 font-semibold"
                        trigger-content-gap="gap-4"
                    >
                        <ul
                            class="flex flex-wrap gap-4 pb-4"
                            aria-label="Liste der verfügbaren Zahlungsmethoden"
                        >
                            <li
                                v-for="(paymentMethod, index) in paymentMethods"
                                :key="`${paymentMethod.name}-${index}`"
                            >
                                <NuxtImg
                                    width="64"
                                    height="32"
                                    :src="paymentMethod.logo"
                                    fit="contain"
                                    decoding="auto"
                                    :alt="`Zahlungsart ${paymentMethod.name}`"
                                    loading="lazy"
                                />
                            </li>
                        </ul>
                    </JamBaseCollapse>
                </div>
            </div>
            <hr />
            <div class="flex flex-col gap-6 lg:flex-row lg:justify-between">
                <div
                    class="flex flex-col gap-6 text-gray-600 lg:flex-row lg:gap-7"
                >
                    <NuxtLink
                        :to="`${k4_Url}`"
                        class="grid place-items-center"
                        target="_blank"
                    >
                        <div
                            class="flex flex-col items-center gap-4 lg:items-start"
                        >
                            <BaseLogo class="w-[125px]" />
                            <NuxtImg
                                width="156"
                                height="16"
                                src="/norika.svg"
                                decoding="auto"
                                alt="part of the norika group"
                                loading="lazy"
                            />
                        </div>
                    </NuxtLink>
                    <div>
                        <ul
                            class="flex h-full items-center justify-center gap-7"
                        >
                            <li
                                v-for="(legalLink, index) in legalLinks"
                                :key="`${legalLink.label}-${index}`"
                            >
                                <JamBaseLink
                                    variant="gray"
                                    :href="legalLink.url"
                                    as="nuxt-link"
                                    :external="legalLink.isExternal"
                                    :target="
                                        legalLink.isExternal ? 'blank_' : ''
                                    "
                                    >{{ legalLink.label }}
                                </JamBaseLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="flex items-center justify-center gap-4">
                    <li
                        v-for="(socialMediaLink, index) in socialMediaLinks"
                        :key="`${socialMediaLink.name}-${index}`"
                    >
                        <NuxtLink
                            :to="socialMediaLink.url"
                            external
                            class="grid place-items-center"
                            target="_blank"
                        >
                            <NuxtImg
                                width="32"
                                height="32"
                                :src="socialMediaLink.logo"
                                :alt="`Logo von ${socialMediaLink.name}`"
                                fit="contain"
                                loading="lazy"
                                decoding="auto"
                            />
                        </NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} | karriere.at` : 'karriere.at';
    },
});

const { dataLayer } = useGtm();
dataLayer.push({ content_group_detail: 'E-Commerce' });
</script>

<template>
    <div class="flex min-h-screen flex-col text-gray-900">
        <NuxtLoadingIndicator color="#142542" :height="2" class="shadow-lg" />
        <TheNavigationBar />

        <header v-if="$slots.header">
            <slot name="header" />
        </header>
        <main class="grow pt-[60px]">
            <slot />
        </main>
        <TheFooter />
    </div>
</template>

<script setup lang="ts">
import { useCart } from '@b2b-platform/commerce/composables/useCart';
import { formatPrice } from '@b2b-platform/commerce/utils/format';
const {
    cart,
    deleteLineItem,
    newItem,
    closeCart,
    isCartOpen,
    disableDeleteButton,
} = useCart();
import { useModal } from '@b2b-platform/commerce/composables/useModal';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { OnClickOutside } from '@vueuse/components';

const { toggle: toggleSmartBewerbenModal, visible: smartBewerbenModalVisible } =
    useModal();
const { toggle: toggleEmployerModal, visible: employerModalVisible } =
    useModal();
const { toggle: toggleBewerberModal, visible: bewerberModalVisible } =
    useModal();
</script>

<template>
    <Popover class="relative">
        <div class="relative">
            <button title="Warenkorb" class="relative">
                <PopoverButton
                    class="font-medium w-full justify-center rounded-md py-2 text-sm focus:outline-none focus-visible:ring-2"
                >
                    <BaseIcon
                        icon-name="cart"
                        size="larger"
                        aria-label="Einkaufswagen"
                    ></BaseIcon>

                    <BaseBadge
                        v-if="cart && cart.totalQuantity > 0"
                        variant="red"
                        rounded="rounded"
                        size="standard"
                        class="absolute -right-3 top-0"
                    >
                        {{ cart.totalQuantity }}
                    </BaseBadge>
                </PopoverButton>
            </button>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <OnClickOutside @trigger="closeCart">
                    <PopoverPanel
                        :static="isCartOpen"
                        class="fixed inset-0 flex min-h-[300px] w-full flex-col gap-5 rounded-l bg-white p-5 shadow-lg md:absolute md:inset-auto md:-right-5 md:top-9 md:w-[480px] md:px-6 md:pb-6"
                    >
                        <div class="flex items-center justify-between">
                            <h2 class="text-lg font-semibold text-gray-900">
                                Warenkorb
                            </h2>
                            <span class="arrow-up"></span>

                            <PopoverButton
                                class="absolute right-0 top-0 m-2 cursor-pointer p-4 text-gray-600 hover:text-gray-800 md:hidden"
                                @click="closeCart"
                            >
                                <JamBaseIcon icon-name="close" size="large" />
                            </PopoverButton>
                        </div>
                        <div
                            v-if="newItem"
                            class="my-4 flex gap-3"
                            aria-label="Produkt wurde zum Warenkorb hinzugefügt"
                        >
                            <BaseIcon
                                icon-name="check"
                                size="large"
                                class="text-green-700"
                            />
                            <BaseText variant="bold">
                                Produkt wurde zum Warenkorb hinzugefügt
                            </BaseText>
                        </div>
                        <div
                            v-if="
                                cart &&
                                (cart.lineItems?.data.length > 0 ||
                                    cart.jobAdLineItems?.data.length > 0)
                            "
                        >
                            <ul
                                class="flex w-full flex-col items-start gap-1 overflow-y-auto md:max-h-[250px]"
                            >
                                <li
                                    v-for="lineItem in cart.lineItems.data"
                                    :key="lineItem"
                                    class="mb-4 w-full bg-gray-50 p-4"
                                >
                                    <div class="flex justify-between">
                                        <BaseText
                                            variant="bold"
                                            class="flex gap-2"
                                        >
                                            {{ lineItem.quantity }}x
                                            {{ lineItem.name }}
                                            <button
                                                :disabled="disableDeleteButton"
                                                @click="
                                                    deleteLineItem(lineItem.id)
                                                "
                                            >
                                                <BaseIcon
                                                    icon-name="trash"
                                                    size="large"
                                                    aria-label="Produkt löschen"
                                                />
                                            </button>
                                        </BaseText>
                                        <BaseText variant="bold">
                                            {{
                                                formatPrice(
                                                    lineItem.price.amount *
                                                        lineItem.quantity,
                                                )
                                            }}
                                        </BaseText>
                                    </div>

                                    <ul>
                                        <li
                                            v-for="addon in lineItem.addons
                                                ?.data"
                                            :key="addon"
                                            class="flex justify-between"
                                        >
                                            <BaseText
                                                class="mt-3 flex gap-2"
                                                variant="small"
                                            >
                                                + {{ addon.name }}
                                                <button
                                                    :disabled="
                                                        disableDeleteButton
                                                    "
                                                    @click="
                                                        deleteLineItem(addon.id)
                                                    "
                                                >
                                                    <BaseIcon
                                                        icon-name="trash"
                                                        size="large"
                                                        aria-label="Produkt löschen"
                                                    />
                                                </button>
                                            </BaseText>

                                            <BaseText
                                                variant="small-bold"
                                                class="content-center"
                                            >
                                                {{
                                                    formatPrice(
                                                        addon.price.amount *
                                                            lineItem.quantity,
                                                    )
                                                }}
                                            </BaseText>
                                        </li>
                                    </ul>
                                </li>
                                <li class="w-full">
                                    <ul
                                        v-if="
                                            cart.jobAdLineItems &&
                                            cart.jobAdLineItems.data.length > 0
                                        "
                                    >
                                        <li
                                            v-for="lineItem in cart
                                                .jobAdLineItems.data"
                                            :key="lineItem"
                                            class="mb-4 w-full bg-gray-50 p-4"
                                        >
                                            <div
                                                class="mb-2 flex justify-between"
                                            >
                                                <BaseText
                                                    variant="bold"
                                                    class="flex gap-2"
                                                >
                                                    {{ lineItem.quantity }}x
                                                    {{ lineItem.name }}
                                                    <button
                                                        :disabled="
                                                            disableDeleteButton
                                                        "
                                                        @click="
                                                            deleteLineItem(
                                                                lineItem.id,
                                                            )
                                                        "
                                                    >
                                                        <BaseIcon
                                                            icon-name="trash"
                                                            size="large"
                                                            aria-label="Produkt löschen"
                                                        />
                                                    </button>
                                                </BaseText>
                                                <BaseText variant="bold">
                                                    {{
                                                        formatPrice(
                                                            lineItem.price
                                                                .amount,
                                                        )
                                                    }}
                                                </BaseText>
                                            </div>
                                            <BaseText variant="small">
                                                - Inserat Job-ID
                                                <BaseText
                                                    variant="small-bold"
                                                    as="span"
                                                >
                                                    {{ cart.jobId }}
                                                </BaseText>
                                            </BaseText>
                                        </li>
                                    </ul>
                                </li>
                                <li
                                    v-if="cart.hasJobAdProduct"
                                    class="mb-4 w-full bg-gray-50 p-4"
                                >
                                    <!-- smart bewerben -->
                                    <div class="mb-2 flex justify-between">
                                        <BaseText variant="bold">
                                            <span class="flex gap-x-3">
                                                <BaseSmartBewerbenLogo
                                                    variant="pill"
                                                    class="h-6"
                                                    areal-label="Smart Bewerben Logo"
                                                />

                                                <button
                                                    type="button"
                                                    class="align-middle text-gray-600 hover:text-green-800"
                                                    @click="
                                                        toggleSmartBewerbenModal
                                                    "
                                                >
                                                    <BaseIcon
                                                        icon-name="info"
                                                        size="large"
                                                        aria-label="Mehr Infos"
                                                    />
                                                </button>
                                            </span>
                                        </BaseText>
                                        <BaseText variant="bold">
                                            inklusive
                                        </BaseText>
                                        <BaseModal
                                            v-model="smartBewerbenModalVisible"
                                            :visible="smartBewerbenModalVisible"
                                        >
                                            <template #title>
                                                <BaseHeadline
                                                    variant="h3"
                                                    class="mb-2"
                                                >
                                                    smart bewerben
                                                </BaseHeadline>
                                            </template>
                                            <template #body>
                                                <BaseText class="text-gray-900">
                                                    Ein einheitlicher
                                                    Bewerbungsprozess bietet
                                                    Talenten einen klaren,
                                                    transparenten Bewerbungsweg.
                                                    Sie sparen Zeit und finden
                                                    alle Bewerbungen
                                                    übersichtlich strukturiert
                                                    in Ihrem
                                                    Bewerbermanagement-System.
                                                </BaseText>
                                            </template>
                                        </BaseModal>
                                    </div>

                                    <!-- bewerber.manager -->
                                    <div class="mb-2 flex justify-between">
                                        <BaseText variant="bold">
                                            bewerber.manager
                                            <button
                                                type="button"
                                                class="align-middle text-gray-600 hover:text-green-800"
                                                @click="toggleBewerberModal"
                                            >
                                                <BaseIcon
                                                    icon-name="info"
                                                    size="large"
                                                    aria-label="Mehr Infos"
                                                />
                                            </button>
                                        </BaseText>
                                        <BaseText variant="bold">
                                            inklusive
                                        </BaseText>
                                        <BaseModal
                                            v-model="bewerberModalVisible"
                                            :visible="bewerberModalVisible"
                                        >
                                            <template #title>
                                                <BaseHeadline
                                                    variant="h3"
                                                    class="mb-2"
                                                >
                                                    bewerber.manager
                                                </BaseHeadline>
                                            </template>
                                            <template #body>
                                                <BaseText class="text-gray-900">
                                                    Der bewerber.manager ist ein
                                                    Tool zur effizienten und
                                                    gesetzeskonformen Verwaltung
                                                    von Bewerbungen, umfasst die
                                                    Basisfunktionen eines
                                                    Bewerbermanagementsystems
                                                    und ist für karriere.at
                                                    Kunden inklusive.
                                                </BaseText>
                                                <BaseText class="text-gray-900">
                                                    Wenn Sie den
                                                    bewerber.manager nutzen
                                                    wollen, müssen Sie ihn
                                                    aktivieren lassen.
                                                    Kontaktieren Sie uns hierzu
                                                    bitte unter
                                                    <a
                                                        href="mailto:shop-support@karriere.at"
                                                        class="font-semibold underline"
                                                        >shop-support@karriere.at</a
                                                    >.
                                                </BaseText>
                                            </template>
                                        </BaseModal>
                                    </div>

                                    <!-- employer.page -->
                                    <div
                                        v-if="!cart.hasEmployerProfileProduct"
                                        class="mb-2 flex justify-between"
                                    >
                                        <BaseText variant="bold">
                                            employer.page
                                            <button
                                                type="button"
                                                class="align-middle text-gray-600 hover:text-green-800"
                                                @click="toggleEmployerModal"
                                            >
                                                <BaseIcon
                                                    icon-name="info"
                                                    size="large"
                                                    aria-label="Mehr Infos"
                                                />
                                            </button>
                                        </BaseText>
                                        <BaseText variant="bold">
                                            inklusive
                                        </BaseText>
                                        <BaseModal
                                            v-model="employerModalVisible"
                                            :visible="employerModalVisible"
                                            title="employer.page"
                                            body="Ihr Arbeitgeberprofil direkt auf karriere.at. Präsentieren Sie Talenten die wichtigsten Informationen zu Ihrem Unternehmen direkt auf karriere.at."
                                        />
                                    </div>
                                </li>
                            </ul>
                            <hr />
                            <div v-if="cart.discountCode">
                                <div
                                    v-for="(discount, idx) in cart.discountCode"
                                    :key="idx"
                                >
                                    <div
                                        v-if="discount.amount.amount > 0"
                                        class="mt-3 flex justify-between"
                                    >
                                        <BaseText variant="large">
                                            Gutscheincode „{{ discount.code }}“
                                        </BaseText>
                                        <BaseText
                                            variant="large"
                                            class="text-red-700"
                                        >
                                            -
                                            {{
                                                formatPrice(
                                                    discount.amount.amount,
                                                )
                                            }}
                                        </BaseText>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 flex justify-between">
                                <BaseText variant="large">
                                    Gesamt (exkl. Ust.)
                                </BaseText>
                                <BaseText variant="large">
                                    {{
                                        formatPrice(
                                            cart.totalPriceWithoutTaxes.amount,
                                        )
                                    }}
                                </BaseText>
                            </div>
                            <div class="mt-5 gap-3 md:flex">
                                <PopoverButton
                                    class="w-full"
                                    as="a"
                                    href="/#products"
                                    @click="closeCart"
                                >
                                    <JamBaseButton
                                        variant="green-outline"
                                        class="w-full"
                                    >
                                        Weiter einkaufen
                                    </JamBaseButton>
                                </PopoverButton>
                                <JamBaseButton
                                    class="mt-4 w-full md:mt-0"
                                    :to="`${
                                        useRuntimeConfig().public.businessPortal
                                            .url
                                    }/shop/checkout`"
                                    @click="closeCart"
                                >
                                    Kauf abschließen
                                </JamBaseButton>
                            </div>
                        </div>
                        <div v-else class="mt-6 flex flex-col items-center">
                            <img
                                src="/p/icons/illu_cart.svg"
                                alt="Empty Cart"
                                class="h-20 w-20"
                            />
                            <p class="mt-4 text-gray-600">
                                Ihr Warenkorb ist aktuell noch leer.
                            </p>
                            <p class="text-gray-600">
                                Fügen Sie Produkte hinzu.
                            </p>
                            <div class="mt-6 text-center">
                                <div>
                                    <PopoverButton
                                        as="a"
                                        href="/#products"
                                        class="font-semibold text-green-700 underline"
                                        @click="closeCart"
                                    >
                                        Jetzt Produkte entdecken
                                    </PopoverButton>
                                </div>
                            </div>
                        </div>
                    </PopoverPanel>
                </OnClickOutside>
            </transition>
        </div>
    </Popover>
</template>

<style scoped>
.arrow-up {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    position: absolute;
    top: -10px;
    right: 20px;
}
</style>

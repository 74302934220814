<template>
    <HeadlessMenu as="div" class="relative">
        <HeadlessMenuButton class="flex">
            <slot name="trigger" />
        </HeadlessMenuButton>
        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <HeadlessMenuItems
                class="absolute right-0 mt-2 flex min-w-[300px] flex-col rounded border border-gray-300 bg-white shadow-md focus:outline-none"
            >
                <slot />
            </HeadlessMenuItems>
        </transition>
    </HeadlessMenu>
</template>

<script setup lang="ts"></script>

<template>
    <HeadlessMenuItem v-slot="{ active }">
        <div
            class="flex items-center rounded"
            :class="{ 'bg-gray-100': active }"
        >
            <slot></slot>
        </div>
    </HeadlessMenuItem>
</template>

import { ref } from 'vue';

type UseModalProps = {
    /**
     * Set initial visibility
     * @default false
     */
    initialVisible?: boolean;
};

const defaultProps: Partial<UseModalProps> = {
    initialVisible: false,
};

export function useModal(props: UseModalProps = defaultProps) {
    const visible = ref(props.initialVisible);

    const show = () => {
        visible.value = true;
    };

    const hide = () => {
        visible.value = false;
    };

    const toggle = () => {
        if (visible.value) hide();
        else show();
    };

    return {
        hide,
        show,
        toggle,
        visible,
    };
}

<template>
    <svg viewBox="0 0 138 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="137.967" height="24" rx="2.25" fill="#142542" />
        <path
            d="M16.8966 15.6164C16.6821 15.9275 16.354 16.1549 15.9123 16.3105C15.4706 16.4661 14.9405 16.5499 14.2843 16.5499C13.8047 16.5499 13.3882 16.5259 13.0475 16.4661C12.7067 16.4062 12.3534 16.3105 12.0126 16.1789V14.7068C12.3912 14.8624 12.7825 14.994 13.2115 15.1017C13.6406 15.2094 14.0192 15.2573 14.3474 15.2573C14.7133 15.2573 14.9784 15.2094 15.1298 15.1017C15.2813 14.994 15.3696 14.8624 15.3696 14.6948C15.3696 14.5871 15.3317 14.4914 15.2686 14.3956C15.2055 14.2999 15.0667 14.2041 14.8648 14.0964C14.6629 13.9887 14.3221 13.8451 13.8804 13.6656C13.4513 13.498 13.098 13.3185 12.8203 13.1509C12.5427 12.9834 12.3407 12.768 12.2019 12.5286C12.0631 12.2892 12 11.9781 12 11.619C12 11.0206 12.2524 10.5658 12.7446 10.2547C13.2368 9.94348 13.9056 9.79987 14.726 9.79987C15.155 9.79987 15.5589 9.83577 15.9501 9.91955C16.3413 10.0033 16.7326 10.135 17.149 10.3145L16.5811 11.5951C16.2404 11.4515 15.9249 11.3437 15.622 11.248C15.3191 11.1523 15.0162 11.1164 14.7007 11.1164C14.4231 11.1164 14.2212 11.1523 14.0823 11.2241C13.9435 11.2959 13.8678 11.4036 13.8678 11.5472C13.8678 11.6549 13.9056 11.7507 13.9814 11.8225C14.0571 11.8943 14.1959 11.99 14.3978 12.0977C14.5998 12.2055 14.9153 12.3251 15.3191 12.4807C15.7103 12.6363 16.0511 12.7919 16.3413 12.9594C16.6316 13.127 16.8462 13.3305 17.0102 13.5698C17.1743 13.8092 17.25 14.1323 17.25 14.5153C17.25 14.9581 17.1364 15.3291 16.9219 15.6403L16.8966 15.6164Z"
            fill="#8BC72A"
        />
        <path
            d="M27.5276 10.2707C27.904 10.6592 28.0922 11.2783 28.0922 12.1281V16.4501H26.3398V12.5773C26.3398 12.1039 26.2574 11.7396 26.1045 11.509C25.9516 11.2783 25.7047 11.1448 25.3753 11.1448C24.9167 11.1448 24.5873 11.3147 24.3874 11.6547C24.1875 11.9946 24.0934 12.4802 24.0934 13.1236V16.4501H22.341V12.5773C22.341 12.2617 22.3057 11.9946 22.2351 11.7882C22.1645 11.5818 22.0587 11.4119 21.9176 11.3026C21.7764 11.1933 21.6 11.1448 21.3765 11.1448C21.0472 11.1448 20.8002 11.2297 20.6121 11.3997C20.4239 11.5697 20.2945 11.8125 20.2122 12.1403C20.1299 12.4681 20.0946 12.8687 20.0946 13.33V16.4501H18.3422V9.82148H19.6829L19.9182 10.6713H20.0123C20.1416 10.4406 20.3063 10.2585 20.5062 10.1128C20.7062 9.96716 20.9179 9.87004 21.1531 9.7972C21.3883 9.72435 21.6353 9.70007 21.8823 9.70007C22.3527 9.70007 22.7526 9.77291 23.0819 9.94288C23.4112 10.1128 23.6582 10.3435 23.8346 10.6834H23.9875C24.1757 10.3435 24.4462 10.1007 24.799 9.94288C25.1519 9.78506 25.5047 9.71221 25.8811 9.71221C26.6103 9.71221 27.163 9.90646 27.5276 10.2949V10.2707Z"
            fill="#8BC72A"
        />
        <path
            d="M34.1153 10.2705C34.5958 10.6508 34.8422 11.2212 34.8422 12.0055V16.3312H33.5609L33.2036 15.4518H33.1543C32.9572 15.6895 32.76 15.8678 32.5629 16.0223C32.3658 16.1767 32.1317 16.2718 31.873 16.3431C31.6142 16.4144 31.2939 16.4501 30.9243 16.4501C30.5301 16.4501 30.1728 16.3788 29.8524 16.2243C29.5321 16.0698 29.2857 15.8559 29.1132 15.5469C28.9407 15.2379 28.8422 14.8576 28.8422 14.3942C28.8422 13.7049 29.0886 13.1939 29.5937 12.873C30.0988 12.5522 30.8504 12.362 31.8483 12.3264L33.0188 12.2907V12.0055C33.0188 11.6609 32.9325 11.4113 32.7477 11.2569C32.5629 11.1024 32.3042 11.0192 31.9839 11.0192C31.6635 11.0192 31.3432 11.0667 31.0229 11.1499C30.7025 11.2331 30.3945 11.3519 30.0865 11.4826L29.4828 10.2824C29.8401 10.1041 30.2344 9.96152 30.6779 9.85456C31.1214 9.74761 31.5773 9.70007 32.0578 9.70007C32.9572 9.70007 33.6471 9.89021 34.1276 10.2705H34.1153ZM32.3042 13.3365C31.7128 13.3484 31.2939 13.4554 31.0721 13.6455C30.8504 13.8356 30.7272 14.0852 30.7272 14.3942C30.7272 14.6675 30.8134 14.8576 30.9736 14.9765C31.1337 15.0953 31.3555 15.1429 31.6142 15.1429C32.0085 15.1429 32.3411 15.0359 32.6122 14.8101C32.8832 14.5843 33.0188 14.2635 33.0188 13.8475V13.3127L32.3042 13.3365Z"
            fill="#8BC72A"
        />
        <path
            d="M39.4965 9.60526C39.6021 9.60526 39.6866 9.62954 39.75 9.64168L39.6338 11.3413C39.6338 11.3413 39.507 11.3049 39.412 11.2928C39.3169 11.2806 39.2324 11.2806 39.169 11.2806C38.9683 11.2806 38.7676 11.3049 38.5775 11.3656C38.3873 11.4263 38.2183 11.5234 38.0599 11.657C37.9014 11.7905 37.7852 11.9605 37.7007 12.179C37.6162 12.3975 37.5739 12.6525 37.5739 12.9681V16.3431H36V9.71452H37.1937L37.4261 10.8314H37.5C37.6162 10.6008 37.7535 10.3944 37.9225 10.2123C38.0916 10.0302 38.2923 9.87235 38.5035 9.76308C38.7148 9.65382 38.9577 9.59312 39.2113 9.59312C39.2852 9.59312 39.3803 9.59312 39.4859 9.60526H39.4965Z"
            fill="#8BC72A"
        />
        <path
            d="M43.7936 14.8208C43.9425 14.784 44.1012 14.7349 44.25 14.6735V16.0362C44.0913 16.1221 43.8929 16.1958 43.6647 16.2572C43.4365 16.3186 43.1786 16.3431 42.9008 16.3431C42.5734 16.3431 42.2857 16.2817 42.0278 16.1467C41.7698 16.0116 41.5714 15.7907 41.4226 15.4715C41.2738 15.1523 41.2044 14.698 41.2044 14.121V10.8922H40.5V10.1188L41.3135 9.50495L41.7401 8.09312H42.6825V9.51723H44.2004V10.8922H42.6825V14.121C42.6825 14.3788 42.7421 14.563 42.8611 14.698C42.9802 14.8331 43.1389 14.8822 43.3274 14.8822C43.4861 14.8822 43.6448 14.8576 43.8036 14.8208H43.7936Z"
            fill="#8BC72A"
        />
        <path
            d="M50.8451 9.52159C50.8451 9.76737 50.8451 10.0014 50.8216 10.2355C50.798 10.4696 50.7863 10.6568 50.7745 10.7856H50.8451C51.0098 10.5281 51.2451 10.294 51.5392 10.1185C51.8333 9.94292 52.2098 9.83759 52.6686 9.83759C53.3863 9.83759 53.9745 10.1185 54.4216 10.6802C54.8686 11.242 55.0922 12.0613 55.0922 13.138C55.0922 13.8636 54.9863 14.4722 54.7863 14.9637C54.5863 15.4553 54.2922 15.8298 53.9157 16.0756C53.5392 16.3213 53.1157 16.4501 52.6216 16.4501C52.1275 16.4501 51.7745 16.3681 51.5039 16.2043C51.2333 16.0405 51.0098 15.8532 50.8451 15.6425H50.7275L50.4333 16.3447H49.0922V7.45007H50.8451V9.52159ZM51.3745 11.4176C51.198 11.5463 51.0686 11.7336 50.9863 11.9793C50.9039 12.2251 50.8569 12.5528 50.8569 12.939V13.1263C50.8569 13.7466 50.951 14.2264 51.1275 14.5541C51.3039 14.8818 51.6451 15.0457 52.1275 15.0457C52.4922 15.0457 52.7745 14.8818 52.9863 14.5541C53.198 14.2264 53.3039 13.7466 53.3039 13.1146C53.3039 12.4826 53.198 12.0144 52.9863 11.6984C52.7745 11.3825 52.4804 11.2186 52.1039 11.2186C51.798 11.2186 51.551 11.2771 51.3745 11.4059V11.4176Z"
            fill="white"
        />
        <path
            d="M60.4823 10.0465C60.9117 10.2735 61.2577 10.608 61.4843 11.0501C61.7109 11.4921 61.8422 12.0297 61.8422 12.6629V13.5231H57.6434C57.6672 14.0249 57.8104 14.4191 58.0966 14.7058C58.3829 14.9926 58.7646 15.1359 59.2656 15.1359C59.6831 15.1359 60.0648 15.0881 60.4107 15.0045C60.7567 14.9209 61.1145 14.7895 61.4724 14.6222V15.9961C61.1503 16.1514 60.8163 16.2709 60.4585 16.3426C60.1006 16.4142 59.6831 16.4501 59.1821 16.4501C58.5261 16.4501 57.9535 16.3306 57.4525 16.0917C56.9515 15.8527 56.5579 15.4824 56.2716 14.9926C55.9853 14.5027 55.8422 13.8815 55.8422 13.1288C55.8422 12.3762 55.9734 11.731 56.2239 11.2293C56.4744 10.7275 56.8442 10.3452 57.3094 10.0824C57.7746 9.81954 58.3113 9.70007 58.9197 9.70007C59.528 9.70007 60.041 9.81954 60.4704 10.0465H60.4823ZM58.0847 11.301C57.8581 11.5279 57.7269 11.8744 57.6911 12.3403H60.1841C60.1841 12.0775 60.1364 11.8386 60.041 11.6355C59.9455 11.4324 59.8143 11.2651 59.6354 11.1456C59.4565 11.0262 59.2298 10.9664 58.9436 10.9664C58.5976 10.9664 58.3113 11.074 58.0847 11.301Z"
            fill="white"
        />
        <path
            d="M67.7702 14.0931C67.7468 13.9574 67.7001 13.7476 67.6416 13.4638C67.5832 13.18 67.513 12.8961 67.4429 12.5753C67.3727 12.2545 67.3026 11.9706 67.2441 11.6992C67.1857 11.4277 67.1389 11.2302 67.1155 11.1068H67.0688C67.0454 11.2302 66.9986 11.4277 66.9402 11.6992C66.8817 11.9706 66.8233 12.2668 66.7531 12.5753C66.683 12.8838 66.6128 13.1923 66.5544 13.4761C66.4959 13.7599 66.4492 13.9697 66.4141 14.1178L65.8997 16.4501H64.0292L62.2172 9.71241H63.9474L64.6839 12.6987C64.7306 12.9085 64.7774 13.1553 64.8242 13.4391C64.8709 13.7229 64.906 14.0067 64.9411 14.2659C64.9762 14.525 64.9995 14.7471 65.0229 14.9076H65.0697C65.0697 14.7965 65.0931 14.6361 65.1164 14.4386C65.1398 14.2412 65.1749 14.0438 65.1983 13.834C65.2217 13.6242 65.2567 13.4391 65.2918 13.2787C65.3269 13.1183 65.3386 12.9949 65.3619 12.9332L66.1452 9.70007H68.0625L68.8107 12.9332C68.8341 13.0442 68.8691 13.2293 68.9159 13.4885C68.9627 13.7476 68.9977 14.0067 69.0328 14.2659C69.0679 14.525 69.0913 14.7471 69.0913 14.8952H69.138C69.1497 14.7595 69.1848 14.562 69.2199 14.2906C69.2549 14.0191 69.3017 13.7353 69.3484 13.4391C69.3952 13.1429 69.4537 12.8961 69.5004 12.6864L70.2603 9.70007H71.9672L70.1317 16.4377H68.2378L67.7468 14.0808L67.7702 14.0931Z"
            fill="white"
        />
        <path
            d="M76.9823 10.0465C77.4117 10.2735 77.7577 10.608 77.9843 11.0501C78.2109 11.4921 78.3422 12.0297 78.3422 12.6629V13.5231H74.1433C74.1672 14.0249 74.3103 14.4191 74.5966 14.7058C74.8829 14.9926 75.2646 15.1359 75.7656 15.1359C76.1831 15.1359 76.5648 15.0881 76.9107 15.0045C77.2567 14.9209 77.6145 14.7895 77.9724 14.6222V15.9961C77.6503 16.1514 77.3163 16.2709 76.9585 16.3426C76.6006 16.4142 76.1831 16.4501 75.6821 16.4501C75.0261 16.4501 74.4535 16.3306 73.9525 16.0917C73.4515 15.8527 73.0579 15.4824 72.7716 14.9926C72.4853 14.5027 72.3422 13.8815 72.3422 13.1288C72.3422 12.3762 72.4734 11.731 72.7239 11.2293C72.9744 10.7275 73.3441 10.3452 73.8093 10.0824C74.2746 9.81954 74.8113 9.70007 75.4197 9.70007C76.028 9.70007 76.541 9.81954 76.9704 10.0465H76.9823ZM74.5847 11.301C74.3581 11.5279 74.2268 11.8744 74.1911 12.3403H76.6841C76.6841 12.0775 76.6364 11.8386 76.541 11.6355C76.4455 11.4324 76.3143 11.2651 76.1354 11.1456C75.9565 11.0262 75.7298 10.9664 75.4435 10.9664C75.0976 10.9664 74.8113 11.074 74.5847 11.301Z"
            fill="white"
        />
        <path
            d="M82.9636 9.71221C83.0693 9.71221 83.1538 9.73649 83.2172 9.74863L83.101 11.4483C83.101 11.4483 82.9742 11.4119 82.8791 11.3997C82.7841 11.3876 82.6995 11.3876 82.6362 11.3876C82.4355 11.3876 82.2348 11.4119 82.0446 11.4726C81.8545 11.5333 81.6855 11.6304 81.527 11.7639C81.3686 11.8975 81.2524 12.0674 81.1679 12.286C81.0834 12.5045 81.0411 12.7594 81.0411 13.0751V16.4501H79.4672V9.82148H80.6608L80.8932 10.9384H80.9672C81.0834 10.7077 81.2207 10.5013 81.3897 10.3192C81.5587 10.1371 81.7594 9.9793 81.9707 9.87004C82.1819 9.76077 82.4249 9.70007 82.6784 9.70007C82.7524 9.70007 82.8474 9.70007 82.9531 9.71221H82.9636Z"
            fill="white"
        />
        <path
            d="M85.7201 9.52159C85.7201 9.76737 85.7201 10.0014 85.6966 10.2355C85.673 10.4696 85.6613 10.6568 85.6495 10.7856H85.7201C85.8848 10.5281 86.1201 10.294 86.4142 10.1185C86.7083 9.94292 87.0848 9.83759 87.5436 9.83759C88.2613 9.83759 88.8495 10.1185 89.2966 10.6802C89.7436 11.242 89.9672 12.0613 89.9672 13.138C89.9672 13.8636 89.8613 14.4722 89.6613 14.9637C89.4613 15.4553 89.1672 15.8298 88.7907 16.0756C88.4142 16.3213 87.9907 16.4501 87.4966 16.4501C87.0024 16.4501 86.6495 16.3681 86.3789 16.2043C86.1083 16.0405 85.8848 15.8532 85.7201 15.6425H85.6024L85.3083 16.3447H83.9672V7.45007H85.7201V9.52159ZM86.2495 11.4176C86.073 11.5463 85.9436 11.7336 85.8613 11.9793C85.7789 12.2251 85.7319 12.5528 85.7319 12.939V13.1263C85.7319 13.7466 85.826 14.2264 86.0024 14.5541C86.1789 14.8818 86.5201 15.0457 87.0024 15.0457C87.3672 15.0457 87.6495 14.8818 87.8613 14.5541C88.073 14.2264 88.1789 13.7466 88.1789 13.1146C88.1789 12.4826 88.073 12.0144 87.8613 11.6984C87.6495 11.3825 87.3554 11.2186 86.9789 11.2186C86.673 11.2186 86.426 11.2771 86.2495 11.4059V11.4176Z"
            fill="white"
        />
        <path
            d="M95.3573 10.0465C95.7867 10.2735 96.1327 10.608 96.3593 11.0501C96.5859 11.4921 96.7172 12.0297 96.7172 12.6629V13.5231H92.5183C92.5422 14.0249 92.6853 14.4191 92.9716 14.7058C93.2579 14.9926 93.6396 15.1359 94.1406 15.1359C94.5581 15.1359 94.9398 15.0881 95.2857 15.0045C95.6317 14.9209 95.9895 14.7895 96.3474 14.6222V15.9961C96.0253 16.1514 95.6913 16.2709 95.3335 16.3426C94.9756 16.4142 94.5581 16.4501 94.0571 16.4501C93.401 16.4501 92.8285 16.3306 92.3275 16.0917C91.8265 15.8527 91.4329 15.4824 91.1466 14.9926C90.8603 14.5027 90.7172 13.8815 90.7172 13.1288C90.7172 12.3762 90.8484 11.731 91.0989 11.2293C91.3494 10.7275 91.7191 10.3452 92.1843 10.0824C92.6496 9.81954 93.1863 9.70007 93.7947 9.70007C94.403 9.70007 94.916 9.81954 95.3454 10.0465H95.3573ZM92.9597 11.301C92.7331 11.5279 92.6018 11.8744 92.5661 12.3403H95.0591C95.0591 12.0775 95.0114 11.8386 94.916 11.6355C94.8205 11.4324 94.6893 11.2651 94.5104 11.1456C94.3315 11.0262 94.1048 10.9664 93.8185 10.9664C93.4726 10.9664 93.1863 11.074 92.9597 11.301Z"
            fill="white"
        />
        <path
            d="M102.836 10.2707C103.253 10.6592 103.467 11.2783 103.467 12.1281V16.4501H101.693V12.5773C101.693 12.1039 101.61 11.7396 101.443 11.509C101.277 11.2783 101.015 11.1448 100.646 11.1448C100.098 11.1448 99.7291 11.3269 99.5386 11.7032C99.3481 12.0796 99.241 12.6259 99.241 13.33V16.4501H97.4672V9.82148H98.8243L99.0624 10.6713H99.1576C99.3005 10.4406 99.4672 10.2585 99.6814 10.1128C99.8957 9.96716 100.122 9.87004 100.372 9.7972C100.622 9.72435 100.884 9.70007 101.158 9.70007C101.848 9.70007 102.408 9.89432 102.836 10.2828V10.2707Z"
            fill="white"
        />
        <path
            d="M125.623 11.4018L115.83 5.74875C115.371 5.48515 114.8 5.81363 114.8 6.34488V6.3611C114.8 6.60847 114.929 6.83557 115.144 6.95723L122.845 11.4018C123.303 11.6654 123.303 12.3305 122.845 12.5941L115.144 17.0387C114.933 17.1604 114.8 17.3875 114.8 17.6348V17.6511C114.8 18.1823 115.371 18.5108 115.83 18.2472L125.623 12.5941C126.081 12.3305 126.081 11.6654 125.623 11.4018Z"
            fill="#8BC72A"
        />
        <path
            d="M120.29 11.4018L110.497 5.74875C110.039 5.48515 109.467 5.81363 109.467 6.34488V17.6551C109.467 18.1864 110.039 18.5148 110.497 18.2512L120.29 12.5981C120.749 12.3346 120.749 11.6695 120.29 11.4059V11.4018Z"
            fill="#8BC72A"
        />
    </svg>
</template>

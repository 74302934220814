<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;

const { user: userInfo, login, logout } = useUser();
</script>

<template>
    <nav class="fixed top-0 z-20 w-full border-b border-b-gray-200 bg-white">
        <div class="relative">
            <div
                class="mx-auto flex max-w-content justify-between px-3 lg:px-7"
            >
                <div class="flex items-center gap-2 lg:gap-6">
                    <HeadlessPopover v-slot="{ open }" class="lg:hidden">
                        <HeadlessPopoverButton
                            title="Navigation anzeigen"
                            class="p-5 lg:hidden"
                        >
                            <BaseIcon
                                :icon-name="open ? 'close' : 'menu'"
                                size="large"
                            />
                        </HeadlessPopoverButton>

                        <transition
                            enter-active-class="transition-[max-height] duration-700 ease"
                            enter-from-class="max-h-0"
                            enter-to-class="max-h-screen"
                            leave-active-class="transition-[max-height] duration-700 ease"
                            leave-from-class="max-h-screen"
                            leave-to-class="max-h-0"
                        >
                            <HeadlessPopoverPanel
                                class="fixed inset-x-0 bottom-0 top-[57px] flex flex-col justify-between overflow-y-auto bg-white pt-5 text-gray-900"
                            >
                                <div class="flex flex-col">
                                    <CommBaseCollapse
                                        v-if="userInfo"
                                        class="border-b p-5"
                                    >
                                        <template #trigger>
                                            <BaseProfileInfo
                                                class="py-4 text-left"
                                                :src="userInfo.user.avatar"
                                                :name="`${userInfo.firstName} ${userInfo.lastName}`"
                                                :company="
                                                    userInfo.user.company
                                                        .fullName
                                                "
                                            />
                                        </template>
                                        <div
                                            class="flex flex-col border-t border-gray-200 pt-4"
                                        >
                                            <JamBaseLink
                                                external
                                                variant="dark-gray"
                                                :href="`${
                                                    useRuntimeConfig().public
                                                        .businessPortal.url
                                                }/dashboard`"
                                                icon-name="home"
                                                class="py-3"
                                            >
                                                Zum Dashboard
                                            </JamBaseLink>
                                            <JamBaseLink
                                                v-if="
                                                    userInfo.user
                                                        .relatedCompanies
                                                        .length > 1
                                                "
                                                as="a"
                                                variant="dark-gray"
                                                external
                                                :href="
                                                    userInfo.routes.profile
                                                        .companySwitch
                                                "
                                                icon-name="arrows-rotate"
                                                class="py-3"
                                            >
                                                Unternehmen wechseln
                                            </JamBaseLink>
                                            <JamBaseLink
                                                as="button"
                                                variant="dark-gray"
                                                icon-name="logout"
                                                class="py-3"
                                                @click="
                                                    logout({
                                                        provider:
                                                            'b2b-identity-provider',
                                                    })
                                                "
                                            >
                                                Abmelden
                                            </JamBaseLink>
                                        </div>
                                    </CommBaseCollapse>
                                    <JamBaseCollapse
                                        class="border-b"
                                        trigger-text="Produkte"
                                        trigger-position="top"
                                        trigger-variant="dark-gray"
                                        trigger-classes="w-full"
                                        trigger-content-gap=""
                                        trigger-content-classes="justify-between w-full p-5"
                                    >
                                        <div class="flex flex-col gap-5 px-5">
                                            <NavigationPanelProducts />
                                        </div>
                                    </JamBaseCollapse>
                                    <JamBaseCollapse
                                        class="border-b"
                                        trigger-text="HR Know-how"
                                        trigger-position="top"
                                        trigger-variant="dark-gray"
                                        trigger-classes="w-full "
                                        trigger-content-gap=""
                                        trigger-content-classes="sticky top-[60px] justify-between w-full p-5"
                                    >
                                        <div class="flex flex-col gap-5 p-5">
                                            <NavigationPanelHrKnowledge />
                                        </div>
                                    </JamBaseCollapse>
                                    <NuxtLink
                                        :to="`${content_Url}/hr/lohnkostenrechner`"
                                        class="w-full border-b p-5"
                                    >
                                        Lohnkostenrechner
                                    </NuxtLink>
                                </div>

                                <NavigationPanelFooter />
                            </HeadlessPopoverPanel>
                        </transition>
                    </HeadlessPopover>
                    <NuxtLink to="/" class="flex h-full items-center">
                        <BaseLogo class="w-[100px] lg:w-[125px]" />
                    </NuxtLink>
                    <div class="hidden gap-5 lg:flex">
                        <HeadlessPopoverGroup
                            class="flex h-full grow justify-start gap-5 overflow-hidden transition-all duration-150"
                        >
                            <NavigationPopover>
                                <template #popover-button="{ open }">
                                    <NavigationPanelNavItem
                                        label="Produkte"
                                        :open
                                        as="HeadlessPopoverButton"
                                    />
                                </template>
                                <template #panel>
                                    <NavigationPanelProducts />
                                </template>
                            </NavigationPopover>

                            <NavigationPopover>
                                <template #popover-button="{ open }">
                                    <NavigationPanelNavItem
                                        label="HR Know-how"
                                        :open
                                        as="HeadlessPopoverButton"
                                    />
                                </template>
                                <template #panel>
                                    <NavigationPanelHrKnowledge />
                                </template>
                            </NavigationPopover>
                        </HeadlessPopoverGroup>

                        <NavigationPanelNavItem
                            label="Lohnkostenrechner"
                            as="NuxtLink"
                            :to="`${content_Url}/hr/lohnkostenrechner`"
                            external
                        >
                        </NavigationPanelNavItem>
                    </div>
                </div>
                <div class="mr-5 flex items-center gap-3 md:mr-0 lg:gap-5">
                    <div
                        v-if="userInfo"
                        class="hidden h-full items-center gap-5 lg:flex"
                    >
                        <NavigationPanelNavItem
                            label="Zum Dashboard"
                            as="NuxtLink"
                            external
                            :to="`${
                                useRuntimeConfig().public.businessPortal.url
                            }/dashboard`"
                        />
                        <BaseDropdown>
                            <template #trigger>
                                <div class="flex h-full items-center gap-2">
                                    <BaseProfileImage
                                        :src="userInfo.user.avatar"
                                    />
                                    <JamBaseIcon icon-name="caret-down" />
                                </div>
                            </template>
                            <BaseProfileInfo
                                class="m-4 mb-0 border-b border-b-gray-100 pb-4"
                                :src="userInfo.user.avatar"
                                :name="`${userInfo.firstName} ${userInfo.lastName}`"
                                :company="userInfo.user.company.fullName"
                            />
                            <BaseDropdownItem
                                v-if="userInfo.user.relatedCompanies.length > 1"
                            >
                                <JamBaseLink
                                    variant="dark-gray"
                                    :href="
                                        userInfo.routes.profile.companySwitch
                                    "
                                    icon-name="arrows-rotate"
                                    class="h-full w-full p-4"
                                >
                                    Unternehmen wechseln
                                </JamBaseLink>
                            </BaseDropdownItem>
                            <BaseDropdownItem>
                                <JamBaseLink
                                    as="button"
                                    variant="dark-gray"
                                    icon-name="logout"
                                    class="h-full w-full p-4"
                                    @click="
                                        logout({
                                            provider: 'b2b-identity-provider',
                                        })
                                    "
                                >
                                    Abmelden
                                </JamBaseLink>
                            </BaseDropdownItem>
                        </BaseDropdown>
                    </div>
                    <JamBaseButton
                        v-else
                        class="my-3"
                        variant="dark-gray-outline"
                        is-small
                        @click="
                            login({
                                provider: 'b2b-identity-provider',
                                redirectUrl: useRoute().path,
                            })
                        "
                    >
                        Anmelden
                    </JamBaseButton>
                    <NavigationCart />
                </div>
            </div>
        </div>
    </nav>
</template>

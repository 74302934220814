<script setup lang="ts">
const props = defineProps<{
    open?: boolean;
    label: string;
    as: 'HeadlessPopoverButton' | 'NuxtLink';
}>();

const resolvedComponent = computed(() => {
    if (props.as === 'NuxtLink') {
        return resolveComponent('NuxtLink');
    }

    return resolveComponent('HeadlessPopoverButton');
});
</script>

<template>
    <!-- eslint-disable tailwindcss/no-custom-classname -->
    <Component
        :is="resolvedComponent"
        class="nav-item flex cursor-pointer items-center gap-2 py-5"
        :class="{ 'nav-item-active font-semibold': open }"
    >
        {{ label }}
        <JamBaseIcon
            v-if="as === 'HeadlessPopoverButton'"
            icon-name="caret-down"
            class="text-base text-gray-900 transition-all duration-150"
            :class="{ 'rotate-180': open }"
        />
    </Component>
    <!-- eslint-enable tailwindcss/no-custom-classname -->
</template>

<style lang="css">
.nav-item {
    position: relative;
}

.nav-item::after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.25s 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
    @apply bg-gray-900;
}

.nav-item:hover::after,
.nav-item-active::after {
    transform: scaleX(1);
}
</style>

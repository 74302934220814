<template>
    <HeadlessPopover v-slot="{ open }" class="flex h-full">
        <slot name="popover-button" :open="open"></slot>

        <transition
            enter-active-class="duration-500 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-150 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <HeadlessPopoverOverlay
                class="fixed inset-x-0 bottom-0 top-[57px] z-[5] bg-black/25"
            />
        </transition>

        <transition
            enter-active-class="transition-[max-height] duration-700"
            enter-from-class="max-h-0"
            enter-to-class="max-h-[600px]"
            leave-active-class="transition-[max-height] duration-300"
            leave-from-class="max-h-[600px]"
            leave-to-class="max-h-0"
        >
            <HeadlessPopoverPanel
                class="absolute inset-x-0 top-[57px] z-10 overflow-hidden bg-white"
            >
                <slot name="panel"></slot>
            </HeadlessPopoverPanel>
        </transition>
    </HeadlessPopover>
</template>

<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const content_Url = runtimeConfig.public.karriere.content.url;

const premiumContent = {
    category: 'Arbeitsmarktreport',
    title: 'karriere.at Arbeitsmarktreport (Februar 2025)',
    to: `${content_Url}/hr/t/arbeitsmarktreport-2025`,
};

const events = [
    {
        endDate: new Date('2025-04-02 20:00'),
        location:
            'Jakov-Lind-Straße 2, Stiege 2, 5. Stock Stiege 2, 6. Stock, 1020 Wien',
        startDate: new Date('2025-04-02 18:00'),
        title: 'Trend Talk zum Arbeitsmarkt: Wie viel Flexibilität muss sein?\n',
        to: `${content_Url}/hr/e/914700/trend-talk-zum-arbeitsmarkt`,
    },
];

const cardLinks = [
    {
        title: 'Recruiting',
        to: `${content_Url}/hr/t/recruiting`,
    },
    {
        title: 'Arbeitsmarkt',
        to: `${content_Url}/hr/t/arbeitsmarkt`,
    },
    {
        title: 'Arbeitgebermarke',
        to: `${content_Url}/hr/t/arbeitgebermarke`,
    },
];
</script>

<template>
    <div
        class="mx-auto grid max-w-content grid-cols-1 gap-7 lg:grid-cols-[5fr_4fr_3fr] lg:p-7"
    >
        <div class="flex flex-col gap-5">
            <JamBaseLink
                variant="dark-gray"
                :href="`${content_Url}/hr/tipps`"
                data-gtm-element="HEAD: Main Navigation"
                data-gtm-element-detail="Alle Downloads"
            >
                Alle Downloads
            </JamBaseLink>
            <div
                class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2"
            >
                <NavigationPanelCardContent v-bind="premiumContent" />
                <ul
                    class="flex flex-col gap-4 text-lg font-semibold text-white"
                >
                    <li
                        v-for="(link, idx) in cardLinks"
                        :key="`${link.title}-${idx}`"
                    >
                        <NavigationPanelCardContent
                            v-bind="link"
                            variant="slim"
                        ></NavigationPanelCardContent>
                    </li>
                </ul>
            </div>
        </div>
        <div class="flex flex-col gap-5">
            <JamBaseLink
                variant="dark-gray"
                :href="`${content_Url}/hr/events`"
                data-gtm-element="HEAD: Main Navigation"
                data-gtm-element-detail="Alle Events"
            >
                Alle Events
            </JamBaseLink>
            <ul
                v-if="events?.length"
                class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1"
            >
                <li
                    v-for="(event, idx) in events"
                    :key="`${event.title}-${idx}`"
                >
                    <NuxtLink
                        class="group flex h-full flex-col justify-between gap-4 rounded bg-gray-50 transition-all lg:flex-row lg:items-center lg:gap-6 lg:bg-transparent lg:hover:bg-gray-50"
                        external
                        :to="event.to"
                        target="_blank"
                    >
                        <div
                            class="flex h-full flex-col items-start justify-between gap-3 p-5 xl:flex-row xl:gap-6"
                        >
                            <div class="flex justify-center gap-2">
                                <p class="text-4xl font-semibold">
                                    {{ $dayjs(event.startDate).format('DD') }}
                                </p>
                                <div class="self-center">
                                    <p class="uppercase">
                                        {{
                                            $dayjs(event.startDate).format(
                                                'MMM',
                                            )
                                        }}
                                    </p>
                                    <p>
                                        {{
                                            $dayjs(event.startDate).format('YY')
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="line-clamp-3 flex w-full items-end gap-4"
                            >
                                <p
                                    class="line-clamp-3 hyphens-auto font-semibold"
                                >
                                    {{ event.title }}
                                </p>
                                <div
                                    class="flex items-center rounded-full bg-white p-2 lg:hidden"
                                >
                                    <BaseIcon
                                        icon-name="arrow-right"
                                        size="large"
                                        class="h-[20px] w-[20px]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            class="hidden shrink-0 grow overflow-hidden pr-4 lg:block"
                        >
                            <div
                                class="rounded-full bg-white p-3 transition-all duration-300 ease-out lg:translate-y-full lg:opacity-0 lg:group-hover:translate-y-0 lg:group-hover:opacity-100"
                            >
                                <JamBaseIcon
                                    icon-name="arrow-right"
                                    size="medium"
                                />
                            </div>
                        </div>
                    </NuxtLink>
                </li>
            </ul>
            <div v-else class="flex flex-col gap-3">
                <img
                    src="/illustrations/events-empty.svg"
                    width="80"
                    height="64"
                    alt="Keine Events"
                />
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col gap-2">
                        <JamBaseText variant="bold">
                            Derzeit sind keine Events geplant.
                        </JamBaseText>
                        <JamBaseText>
                            Die Business Events von karriere.at bieten
                            kostenlose Einblicke in wichtige HR-Themen wie
                            Recruiting, Branding und Active Sourcing.
                        </JamBaseText>
                    </div>
                    <JamBaseText>
                        Werfen Sie einen Blick auf unsere
                        <JamBaseLink :href="`${content_Url}/hr/events`">
                            vergangenen Events </JamBaseLink
                        >.
                    </JamBaseText>
                </div>
            </div>
        </div>
        <div class="flex flex-col gap-5">
            <JamBaseLink
                variant="dark-gray"
                :href="`${content_Url}/c/p/podcast`"
                target="_blank"
                has-trailing-icon
                icon-name="extern-link"
                icon-size="medium"
                data-gtm-element="HEAD: Main Navigation"
                data-gtm-element-detail="Podcast anhören"
            >
                Podcast anhören
            </JamBaseLink>
            <div
                class="grid h-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-1"
            >
                <NavigationPanelCardContent
                    title="Der Podcast zur Arbeitswelt in Österreich."
                    category="Zeitausgleich."
                    :to="`${content_Url}/c/p/podcast`"
                    target="_blank"
                    external-link
                />
            </div>
        </div>
    </div>
    <NavigationPanelFooter class="hidden lg:block" />
</template>
